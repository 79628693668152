import React from 'react';
import * as styles from './socials.module.scss';

import discord from './icons/discord.svg';
import inst from './icons/instagram.svg';
import twit from './icons/twitter.svg';
import vk from './icons/vk.svg';
import youtube from './icons/youtube.svg';

const list = [
	{
		name: 'twitter',
		link: 'https://twitter.com/scope_gg',
		icon: twit,
	},
	{
		name: 'vk',
		link: 'https://vk.com/scopegg',
		icon: vk,
	},
	{
		name: 'discord',
		link: 'https://discord.com/invite/fPZkGHw',
		icon: discord,
	},
	{
		name: 'youtube',
		link: 'https://www.youtube.com/channel/UCAJJOQv8ZEJEuMuM3d3qD4g',
		icon: youtube,
	},
	{
		name: 'instagram',
		link: 'https://www.instagram.com/scope_gg/',
		icon: inst,
	},
];

const SocialsItem = ({ icon, link, name = 'icon' }) => {
	return (
		<li className={styles.item}>
			<a className={styles.link} href={link} target="_blank" rel="noopener noreferrer">
				<img src={icon} alt={name} />
			</a>
		</li>
	);
};

const Socials = () => {
	return (
		<ul>
			{list.map((item) => {
				const { name, link, icon } = item;

				return <SocialsItem key={name} icon={icon} link={link} name={name} />;
			})}
		</ul>
	);
};

export default Socials;
