import React from 'react';
import * as styles from './sideActions.module.scss';
import LoginActions from '@components/molecules/LoginActions';
import LangSwitcher from '@components/molecules/LangSwitcher';

const SideActions = () => {
	return (
		<div className={styles.container}>
			<div className={styles.lang}>
				<LangSwitcher />
			</div>
			<LoginActions location="Header" />
		</div>
	);
};

export default SideActions;
